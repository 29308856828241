import { Grid, useMediaQuery } from "@mui/material";
import MetaTags from "ReusableComponents/MetaTags/MetaTags";
import { apiClient } from "api/apiClient";
import Euler from "assets/euler.png";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { COLORS } from "theme";
import GalleryImageView from "./GalleryImageView";
import ServiceCommitment from "./ServiceCommitment";
import ServiceHero from "./ServiceHero";
import ServiceLocation from "./ServiceLocation";
import ServiceOptions from "./ServiceOptions";

const ServicePage = () => {
  const small = useMediaQuery("(max-width: 600px)");
  const medium = useMediaQuery("(max-width: 1200px)");
  const [bannerData, setBannerData] = useState([]);
  const [commitment, setCommitment] = useState([]);
  const [gallery, setGallery] = useState([]);

  const [option, setOption] = useState([]);

  useEffect(() => {
    apiClient.getServiceHero().then((res) => {
      setBannerData(res.data);
    });

    apiClient.getServiceCommitment().then((res) => {
      setCommitment(res?.data);
    });
    apiClient.getServiceOption().then((res) => {
      setOption(res?.data);
    });
    apiClient.getServiceGallery().then((res) => {
      setGallery(res?.data);
    });
  }, []);
  return (
    <>
      {!_.isEmpty(bannerData) && (
        <Grid item xs={12}>
          <ServiceHero bannerData={bannerData} small={small} />
        </Grid>
      )}
      {!_.isEmpty(option) && (
        <Grid
          item
          xs={12}
          style={{ paddingBottom: "50px", background: COLORS.lightBlack }}
        >
          <ServiceOptions small={small} option={option} medium={medium} />
        </Grid>
      )}

      <Grid item xs={12} style={{ background: COLORS.black }}>
        <ServiceLocation small={small} medium={medium} />
      </Grid>

      <Grid
        item
        xs={12}
        style={
          small
            ? {
                paddingTop: "210px",
                paddingLeft: "20px",
                paddingRight: "10px",

                background: COLORS.black,
              }
            : { padding: "80px 0px", background: COLORS.black }
        }
      >
        <GalleryImageView pics={gallery} small={small} />
      </Grid>

      {!_.isEmpty(commitment) && (
        <Grid
          item
          xs={12}
          style={{ paddingTop: 50, zIndex: 1, background: COLORS.lightBlack }}
        >
          <ServiceCommitment
            isExtra
            small={small}
            medium={medium}
            inverse={false}
            img={commitment?.attributes.media?.data?.attributes?.url}
            alt={
              commitment?.attributes.media?.data?.attributes?.alternativeText
            }
            color={COLORS.lightGrey}
            name={commitment?.attributes.heading}
            subtitle={commitment?.attributes.subheading}
            description={commitment?.attributes?.description}
            features={[
              {
                id: 1,
                title: commitment?.attributes?.feature1text,

                img: commitment?.attributes?.feature1image?.data?.attributes
                  ?.url,
                alt: commitment?.attributes?.feature1image?.data?.attributes
                  ?.alternativeText,
              },
              {
                id: 2,
                title: commitment?.attributes?.feature2text,
                img: commitment?.attributes?.feature2image?.data?.attributes
                  ?.url,
                alt: commitment?.attributes?.feature2image?.data?.attributes
                  ?.alternativeText,
              },
              {
                id: 3,
                title: commitment?.attributes?.feature3text,
                img: commitment?.attributes?.feature3image?.data?.attributes
                  ?.url,
                alt: commitment?.attributes?.feature3image?.data?.attributes
                  ?.alternativeText,
              },
              {
                id: 4,
                title: commitment?.attributes?.feature4text,
                img: commitment?.attributes?.feature4image?.data?.attributes
                  ?.url,
                alt: commitment?.attributes?.feature4image?.data?.attributes
                  ?.alternativeText,
              },
              {
                id: 5,
                title: commitment?.attributes?.feature5text,
                img: commitment?.attributes?.feature5image?.data?.attributes
                  ?.url,
                alt: commitment?.attributes?.feature5image?.data?.attributes
                  ?.alternativeText,
              },
              {
                id: 6,
                title: commitment?.attributes?.feature6text,
                img: commitment?.attributes?.feature6image?.data?.attributes
                  ?.url,
                alt: commitment?.attributes?.feature6image?.data?.attributes
                  ?.alternativeText,
              },
            ]}
          />
        </Grid>
      )}

      <Helmet>
        <title>Electric Vehicle Showroom Network | Euler Motors</title>
        <link rel="canonical" href="https://www.eulermotors.com/en/charging-network" />
        <link rel="canonical" href="https://www.eulermotors.com/hi/charging-network" />
        <link rel="canonical" href="https://www.eulermotors.com/ta/charging-network" />
        <link rel="canonical" href="https://www.eulermotors.com/te/charging-network" />
        <link rel="canonical" href="https://www.eulermotors.com/mr/charging-network" />
        <link rel="canonical" href="https://www.eulermotors.com/gu/charging-network" />
        <link rel="canonical" href="https://www.eulermotors.com/kn/charging-network" />
        <link rel="canonical" href="https://www.eulermotors.com/bn/charging-network" />
      
        <link
          rel="publisher"
          href="https://www.eulermotors.com/charging-network"
        />
        <meta
          name="title"
          content="Electric Vehicle Showroom Network | Euler Motors"
        />
        <meta
          name="description"
          content="Concerned about finding charging stations or charging hubs for your electric three wheelers? With Euler Motors EV charging solutions, charge your vehicle at the convenience of your home or on the go."
        />
        ​
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.eulermotors.com/showroom-network"
        />
        <meta
          property="og:title"
          content="Electric Vehicle Showroom Network | Euler Motors"
        />
        <meta
          property="og:description"
          content="Concerned about finding charging stations or charging hubs for your electric three wheelers? With Euler Motors EV charging solutions, charge your vehicle at the convenience of your home or on the go."
        />
        <meta property="og:image" content={Euler} />
        ​
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.eulermotors.com/showroom-network"
        />
        <meta
          property="twitter:title"
          content="Electric Vehicle Showroom Network | Euler Motors"
        />
        <meta
          property="twitter:description"
          content="Concerned about finding charging stations or charging hubs for your electric three wheelers? With Euler Motors EV charging solutions, charge your vehicle at the convenience of your home or on the go."
        />
        <meta property="twitter:image" content={Euler} />
        <MetaTags link={"https://www.eulermotors.com/showroom-network"} />
      </Helmet>
    </>
  );
};

export default ServicePage;
