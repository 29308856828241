import { Grid } from "@mui/material";
import { apiClient } from "api/apiClient";
import { useEffect, useState } from "react";
import Box from "ReusableComponents/Box";
import H2 from "ReusableComponents/Text/H2";
import H3 from "ReusableComponents/Text/H3";
import { COLORS, SIZES, WEIGHT } from "theme";
import { ERROR_CODES } from "utils";
import LocationBox from "../ChargingNetwork/components/LocationBox";
import "../ChargingNetwork/style.css";
import { GM } from "./GM";

const ServiceLocation = ({ small, medium }) => {
  const [serviceLocation, setServiceLocation] = useState([]);
  const [stateData, setStateData] = useState([]);

  const [state, setState] = useState({
    state: "Delhi",
    city: "New Delhi",
  });

  useEffect(() => {
    apiClient.getServiceLocation().then((res) => {
      setServiceLocation(res?.data);
      let state = res?.data?.map(
        (item) => item?.attributes?.state_located?.data?.attributes?.state_name
      );
      const filteredStates = state.filter(
        (state) => state && state.trim() !== ""
      );
      const uniqueStates = Array.from(new Set(filteredStates));
      setStateData(uniqueStates);
    });
  }, []);

  const getCity = (state) => {
    let city = serviceLocation
      .filter(
        (item) =>
          item?.attributes?.state_located?.data?.attributes?.state_name == state
      )
      .map(
        (item) => item?.attributes?.city_located?.data?.attributes?.city_name
      );

    const filteredCities = city.filter((city) => city && city.trim() !== "");
    const uniqueCity = Array.from(new Set(filteredCities));

    return uniqueCity;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState({
      ...state,
      [name]: value,
    });
    if (name == "state") {
      setState({
        state: value,
        city: "",
      });
    }
  };

  return (
    <Grid container spacing={2} id="chargingnetwork">
      <Grid item xs={12} md={6} lg={4} style={{ padding: 32, paddingLeft: 54 }}>
        <Box display="flex" flexDirection="column">
          <Box>
            <H2
              textColor={COLORS.white}
              fontSize="24px"
              fontWwight={WEIGHT.bold}
            >
              Service Locator
            </H2>
          </Box>
          <Box marginTop={SIZES.twentyFour} marginBottom={SIZES.sizteen}>
            <Box position="relative">
              <select
                value={state.state}
                name="state"
                onChange={handleChange}
                style={{
                  padding: "12px 24px",
                  width: "100%",
                  background: "none",
                  color: "white",
                }}
              >
                <option value="None" selected>
                  Select State
                </option>

                {state.state &&
                  stateData.map((item) => (
                    <option key={0} value={item}>
                      {item}
                    </option>
                  ))}
              </select>
              <i
                className="arrow down"
                style={{
                  position: "absolute",
                  top: "15px",
                  right: SIZES.twentyFour,
                }}
              ></i>
            </Box>
          </Box>
          <Box position="relative">
            <select
              value={state.city}
              name="city"
              onChange={handleChange}
              style={{
                padding: "12px 24px",
                width: "100%",
                background: "none",
                color: "white",
              }}
            >
              <option value="None" selected>
                Select City
              </option>
              {state.state &&
                getCity(state.state).map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
            </select>

            <i
              className="arrow down"
              style={{
                position: "absolute",
                top: "15px",
                right: SIZES.twentyFour,
              }}
            ></i>
          </Box>
          <Box marginTop="44px" marginBottom="10px" id="locations">
            <H3
              textColor={COLORS.white}
              fontSize="18px"
              fontWwight={WEIGHT.regular}
              margin={SIZES.zero}
            >
              Result
            </H3>
          </Box>
          <Box maxH="330px" minH="330px" overflowY="scroll" id="locations">
            {state.city != "" ? (
              serviceLocation.length &&
              serviceLocation
                .filter(
                  (item) =>
                    item?.attributes?.state_located?.data?.attributes
                      ?.state_name == state.state &&
                    item?.attributes?.city_located?.data?.attributes
                      ?.city_name == state.city
                )
                .map((item) => (
                  <Box margin="8px 0">
                    <LocationBox
                      id={item?.service_point_id}
                      name={item?.attributes?.location_name}
                      place={item?.attributes?.service_point_address}
                      lat={item?.attributes?.latitude}
                      long={item?.attributes?.longitude}
                    />
                  </Box>
                ))
            ) : (
              <H3
                textColor={COLORS.white}
                fontSize="14px"
                fontWwight={WEIGHT.regular}
                padding="50px 50px"
              >
                {ERROR_CODES.notServiceable}
              </H3>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} lg={8} style={{ padding: 0 }}>
        <GM
          isMarkdownShown
          chargingHubs={serviceLocation}
          small={small}
          mapElement={<div style={{ height: small ? "11%" : `100vh` }} />}
        />
      </Grid>
    </Grid>
  );
};
export default ServiceLocation;
