import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "App";
import { store, persistor } from "store/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import ErrorBoundary from "ErrorBoundry";
import * as Sentry from "@sentry/react";

// Sentry.init({
//   dsn: "https://0586d4564fd63e43fb94205d9919d563@o4507729921245184.ingest.us.sentry.io/4507729934024704",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </PersistGate>
  </Provider>
);
